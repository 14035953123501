import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <div className="container-fluid text-white bg-dark d-flex vh-100 justify-content-center align-items-center fade-in-fwd">
      <div className="text-center">
        <h1 className="display-5 fw-bold">Welcome to LocFam's website!</h1>
        <p className="fs-4">
          Select one of the buttons below to start exploring the page!
        </p>
        <div
          className="btn-group"
          role="group"
          aria-label="Landing navigation buttons"
        >
          <button type="button" className="btn btn-lg btn-light pulsate-fwd">
            <Link className="text-decoration-none link-secondary" to="/aboutus">
              What is LocFam
            </Link>
          </button>
          <div className="btn-group" role="group">
            <button
              id="glossary-btn"
              type="button"
              className="btn btn-lg btn-light pulsate-fwd dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Glossary
            </button>
            <ul className="dropdown-menu" aria-labelledby="glossary-btn">
              <li>
                <Link
                  className="text-decoration-none link-dark"
                  to="/glossary/es-la"
                >
                  ES-LA
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
