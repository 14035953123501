import { Workbook, Worksheet } from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import ButBar from "../components/ButBar";
import NavBar from "../components/NavBar";
import TableGen from "../components/TableGen";

const Table = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState<Boolean>(false);
  const [isOrdered, setIsOrdered] = useState<Boolean>(true);
  const [isFiltered, setIsFiltered] = useState<Boolean>(false);
  const [items, setItems] = useState<Array<Row>>([]);
  const [filteredItems, setFilteredItems] = useState<Array<Row>>([]);

  const FETCHURL: any = process.env.REACT_APP_GLOSSARY_URL;

  const SUGGESTIONURL: any = process.env.REACT_APP_SUGGESTION_URL;

  const ExcelJS = require("exceljs");

  interface Row {
    wordId: number;
    sourceWord: String;
    definition: any;
    targetWord: String;
  }

  useEffect(() => {
    let info: Array<Row> = [];
    fetch(FETCHURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
    })
      .then((res) => res.json())
      .then(
        (contents) => {
          setIsLoaded(true);
          contents.map((row: Row) => {
            if (row.definition == "") {
              row.definition = (
                <a href={SUGGESTIONURL}>
                  None yet. Add yours!
                </a>
              );
            }
            return info.push(row);
          });
          setItems(info);
          setFilteredItems(info);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  useEffect(() => { }, [isOrdered, isFiltered]);

  const exportTable: any = (type: String) => {
    const expTble: Workbook = new ExcelJS.Workbook();
    expTble.creator = "LocFam Group";
    expTble.created = new Date();

    const glossary: Worksheet = expTble.addWorksheet("Glossary", {
      headerFooter: {
        firstHeader:
          "Distributed under Attribution-NonCommercial 4.0 International (CC BY-NC 4.0): https://creativecommons.org/licenses/by-nc/4.0/",
      },
    });

    glossary.columns = [
      { header: "English", key: "english", width: 32 },
      { header: "Spanish (LatAm)", key: "spanish", width: 32 },
    ];

    filteredItems.map((row: Row) => {
      glossary.addRow({
        english: row.sourceWord,
        spanish: row.targetWord,
      });
    });

    if (type === "xlsx") {
      expTble.xlsx.writeBuffer().then((data: any) => {
        console.log("xlsx");
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "locfamGlossary_XLSX.xlsx");
      });
    } else {
      expTble.csv
        .writeBuffer({
          encoding: "utf-8",
          formatterOptions: { writeBOM: true },
        })
        .then((data: any) => {
          console.log("csv");
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "locfamGlossary_CSV.csv");
        });
    }
  };

  const sourceSortAlgrtm: any = (a: Row, b: Row) => {
    let result: Number;
    if (isOrdered) {
      setIsOrdered(!isOrdered);
      result =
        b.sourceWord.toUpperCase() > a.sourceWord.toUpperCase() === true
          ? 1
          : -1;
      return result;
    } else {
      setIsOrdered(!isOrdered);
      result =
        a.sourceWord.toUpperCase() > b.sourceWord.toUpperCase() === true
          ? 1
          : -1;
      return result;
    }
  };

  const targetSortAlgrtm: any = (a: Row, b: Row) => {
    let result: Number;
    if (isOrdered) {
      setIsOrdered(!isOrdered);
      result =
        b.targetWord.toUpperCase() > a.targetWord.toUpperCase() === true
          ? 1
          : -1;
      return result;
    } else {
      setIsOrdered(!isOrdered);
      result =
        a.targetWord.toUpperCase() > b.targetWord.toUpperCase() === true
          ? 1
          : -1;
      return result;
    }
  };

  const filterSourceAlgrtm: any = (str: string) => {
    setFilteredItems(items);
    if (str === "") {
      setIsFiltered(false);
    } else {
      setFilteredItems(
        items.filter((row: Row) =>
          row.sourceWord.toUpperCase().startsWith(str.toUpperCase())
        )
      );
      setIsFiltered(true);
    }
  };

  const barProps = {
    glossary: true,
    filterSourceAlgrtm: filterSourceAlgrtm,
    exportTable: exportTable,
  };

  const tableProps = {
    filteredItems: filteredItems,
    sourceSortAlgrtm: sourceSortAlgrtm,
    targetSortAlgrtm: targetSortAlgrtm,
  };

  return (
    <div className="fade-in-fwd">
      <NavBar {...barProps} />
      <TableGen {...tableProps} />
      <ButBar />
    </div>
  );
};

export default Table;
