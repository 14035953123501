import { Link } from "react-router-dom";

const NavBar = (props: any) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="col">
            <Link className="navbar-brand" type="link" to="/landing">
              LocFam
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="col-11">
            <div className="row">
              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                <div className="navbar-nav col">
                  <Link className="nav-link" to="/landing">
                    Home
                  </Link>
                  <Link className="nav-link" to="/aboutus">
                    About Us
                  </Link>
                  <div className="dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Glossary
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <Link
                          className="text-decoration-none link-dark"
                          to="/glossary/es-la"
                        >
                          ES-LA
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {props.glossary && (
                  <div className="input-group col">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search a word"
                      aria-label="Search a word"
                      aria-describedby="button-addon2"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        props.filterSourceAlgrtm(e.target.value)
                      }
                    />
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      id="button-addon2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Export
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <button
                          className="dropdown-item btn btn-outline-primary"
                          onClick={() => props.exportTable("xlsx")}
                        >
                          XLSX
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item btn btn-outline-primary"
                          onClick={() => props.exportTable("csv")}
                        >
                          CSV
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
