import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Landing from "./views/Landing";
import Table from "./views/Table";
import WhatIsLocFam from "./views/WhatIsLocFam";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/landing">
          <Landing />
        </Route>
        <Route path="/glossary/es-la">
          <Table />
        </Route>
        <Route path="/aboutus">
          <WhatIsLocFam />
        </Route>
        <Route path="*">
          <Redirect to="/landing" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
