import React from "react";
import ButBar from "../components/ButBar";
import NavBar from "../components/NavBar";

const WhatIsLocFam = () => {
  return (
    <div className="fade-in-fwd min-vh-100">
      <NavBar />
      <div>
        <div>
          <h6>What is LocFam?</h6>

          <p>
            LocFam is a game localization-centered group founded in the early
            2020s, where people of all levels of experience gather.
          </p>

          <p>
            Inspired by other languages' tightly-knit 'scene' of game
            translators, and the lack of such place for Latin American and
            Brazilian Portuguese translators, we strive to be a place where
            every game translator can take part in thoughtful conversation
            around the topic.
          </p>

          <p>
            Even though our main language focus is Spanish in its Latin American
            "variant" and Brazilian Portuguese, other language pairs are also
            welcomed.
          </p>

          <p>
            Our first project is the Videogames' Translation Glossary, where we
            try to unify, whenever possible, videogame terms for the Latin
            American, Spanish, and Brazilian markets. You can check it out in
            the "Glossary" tab above.
          </p>

          <p>
            For the time being, only the Latin American Spanish version is
            available, although there is also a Brazilian Portuguese and Spain
            Spanish version in the works.
          </p>
        </div>
      </div>
      <div>
        <ButBar />
      </div>
    </div>
  );
};

export default WhatIsLocFam;
