const ButBar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark justify-content-center text-white align-text-middle">
        <p>© 2021 LocFam</p>
      </nav>
    </div>
  );
};

export default ButBar;
