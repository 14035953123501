const TableGen = (props: any) => {
  type Row = {
    sourceWord: String;
    definition: JSX.Element | String;
    targetWord: String;
    id: Number;
  };
  return (
    <div className="table-responsive">
      <table className="table table-hover table-bordered table-sm">
        <thead className="table-dark">
          <tr>
            <th scope="col">
              <button
                className="tb-header btn btn-link text-decoration-none text-white"
                onClick={() => props.filteredItems.sort(props.sourceSortAlgrtm)}
              >
                English
              </button>
            </th>
            <th scope="col">
              <button
                className="tb-header btn btn-link text-decoration-none text-white"
                onClick={() => props.filteredItems.sort(props.targetSortAlgrtm)}
              >
                Spanish (LatAm)
              </button>
            </th>
            <th scope="col">
              <button
                className="tb-header btn text-decoration-none text-white"
                disabled
              >
                Definition
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.filteredItems.map((row: Row) => (
            <tr>
              <td>{row.sourceWord}</td>
              <td>{row.targetWord}</td>
              <td>{row.definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableGen;
